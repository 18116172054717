import { useMemo, useState } from "react";
import { ISelectOption, Select } from "../shared/Select";
import {
  ContentWrapper,
  DebugWrapper,
  OptionsWrapper,
  Padding,
  SelectWrapper,
} from "./style";
import { CreateQuote } from "./CreateQuote";
import { TransferFromDistributor } from "./TransferFromDistributor";

enum OtcEnum {
  CREATE_QUOTE,
  TRANSFER_FROM_DISTRIBUTOR,
}

export function Otc() {
  const [otcOption, setOtcOption] = useState<OtcEnum>(OtcEnum.CREATE_QUOTE);

  const options: ISelectOption[] = useMemo(
    () => [
      {
        label: "Create Quote",
        isActive: otcOption === OtcEnum.CREATE_QUOTE,
        onClick: () => setOtcOption(OtcEnum.CREATE_QUOTE),
      },
      {
        label: "Transfer from Distributor",
        isActive: otcOption === OtcEnum.TRANSFER_FROM_DISTRIBUTOR,
        onClick: () => setOtcOption(OtcEnum.TRANSFER_FROM_DISTRIBUTOR),
      },
    ],
    [otcOption]
  );

  return (
    <DebugWrapper>
      <Padding>
        <OptionsWrapper>
          <SelectWrapper>
            <Select options={options} />
          </SelectWrapper>
        </OptionsWrapper>
        <ContentWrapper>
          {otcOption === OtcEnum.CREATE_QUOTE && <CreateQuote />}
          {otcOption === OtcEnum.TRANSFER_FROM_DISTRIBUTOR && (
            <TransferFromDistributor />
          )}
        </ContentWrapper>
      </Padding>
    </DebugWrapper>
  );
}
