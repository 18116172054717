import axios, { AxiosError } from "axios";
import { useCallback, useContext } from "react";
import { AuthContext } from "../../../context/AuthContext";
import { AdminEndpointEnum } from "../../../enums/endpoints";
import { ADMIN_API_URL } from "../../../utils/env";
import { ToastEnum, ToastStatusEnum, useToast } from "../../toast";

export enum TradeTypeEnum {
  TRADE = "trade",
  LIQUIDATION = "liquidation",
  SETTLEMENT = "settlement",
  FUNDING = "funding",
}

export const useAccountTransfer = () => {
  const { tokenId } = useContext(AuthContext);
  const { addToast } = useToast();

  const transferFromDistributor = useCallback(
    async (account: string, amount_float: string) => {
      try {
        return await await axios
          .post(
            ADMIN_API_URL(AdminEndpointEnum.TRANSFER),
            {
              account,
              amount_float,
            },
            {
              headers: {
                Authorization: `Bearer ${tokenId}`,
              },
            }
          )
          .then(() => {
            addToast(
              {
                type: ToastEnum.SIMPLE,
                header: "Transferred from Distributor",
                subheader: `Account has been transferred $${amount_float}`,
                status: ToastStatusEnum.SUCCESS,
              },
              4000
            );
          });
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);

        addToast(
          {
            type: ToastEnum.SIMPLE,
            header: "Account Transfer Failed",
            subheader: ((error as AxiosError)?.response?.data as any).error,
            status: ToastStatusEnum.ERROR,
          },
          4000
        );
      }
    },
    [addToast, tokenId]
  );

  return {
    transferFromDistributor,
  };
};
