import { useCallback, useState } from "react";
import { Button, ButtonThemeEnum } from "../shared/Buttons/styles";
import { GlobalFilterWrapper } from "../Table/style";
import { DetailsWrapper, FilterContainer } from "./style";
import { COMPONENTS } from "../../constants/design/spacing";
import { Spinner } from "../shared/Spinner";
import { useAccountTransfer } from "../../hooks/api/admin/useAccountTransfer";

export function TransferFromDistributor() {
  const { transferFromDistributor } = useAccountTransfer();
  const [loading, setLoading] = useState<boolean>(false);
  const [account, setAccount] = useState<string>();
  const [transferAmount, setTransferAmount] = useState<string>();

  const onTransferFromDistributor = useCallback(async () => {
    if (!account || !transferAmount) {
      return;
    }
    setLoading(true);
    try {
      await transferFromDistributor(account, transferAmount);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, [account, transferAmount, transferFromDistributor]);

  return (
    <DetailsWrapper>
      <FilterContainer>
        <GlobalFilterWrapper>
          <input
            value={account}
            onChange={(e) => setAccount(e.target.value)}
            placeholder="Account"
          />
          <input
            value={transferAmount}
            onChange={(e) => setTransferAmount(e.target.value)}
            placeholder="Transfer Amount"
          />
        </GlobalFilterWrapper>
        <Button
          buttonTheme={ButtonThemeEnum.NEUTRAL2}
          onClick={() => onTransferFromDistributor()}
          style={{
            minHeight: `${COMPONENTS.selectHeight}px`,
            maxHeight: `${COMPONENTS.selectHeight}px`,
          }}
          disabled={!account || !transferAmount}
        >
          {loading ? <Spinner /> : "Confirm"}
        </Button>
      </FilterContainer>
    </DetailsWrapper>
  );
}
